import Head from 'next/head';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';

import React from 'react';

import Banner from '../components/BannerDefault/index';
import ButtonDefault from '../components/Buttons/ButtonDefault';
import Depoimentos from '../components/Carousel/CarouselDepoimentos';
import MetaTags from '../components/MetaTags';
import {
  payloadArtigos,
  payloadContatos,
  payloadDepoimentos,
  payloadEmpreendimentos,
  payloadImagens,
  payloadListas,
  payloadSedes,
  payloadTextos,
} from '../gql/empreendimentos';
import useMedia from '../hooks/useMedia';
import { customLoader } from '../utils/customLoaderImage';
import { findImage } from '../utils/ImageFindSizes';

import { getCollectionCMSGraphQL, getCollectionCMSUnity } from './api/payloadCMS';
import Formfale from './home/formfale';
import NossosLancamentos from './home/lancamentos';
import Noticias from './home/noticias';
import Politica from './home/politica';
import SobreHype from './home/sobreHype';
import ValoresHype from './home/valores';

export default function Home({
  homePage, lancamentos, artigos, depoimentos,
}) {
  const router = useRouter();
  const rota = `https://hypeempreendimentos.com.br${router.asPath}`;

  const isMobile = useMedia('(max-width: 768px)');

  return (
    <>
      <Head>
        <MetaTags
          data={{
            titulo: homePage?.seo?.seo?.metaTitle,
            descricao: homePage?.seo?.seo?.metaDescription,
            imagem: homePage?.seo?.seo?.ogImage?.url,
            rota,
          }}
        />
      </Head>
      <Banner
        image={{ src: findImage(homePage?.imagens, 'banner-principal')?.imagem?.url }}
        width='100%'
        height={isMobile ? '30vh' : '70vh'}>
        <h1>
          {homePage.textos.find((txt) => txt.referencia === 'home-titulo-banner-principal').texto.conteudo.length > 3
            ? homePage.textos.find((txt) => txt.referencia === 'home-titulo-banner-principal').texto.conteudo
            : ''}
        </h1>
        {homePage?.textos?.find((txt) => txt.referencia === 'home-button-banner-principal')?.texto.conteudo.length > 3 ? (
          <ButtonDefault
            aria={homePage?.textos?.find((txt) => txt.referencia === 'home-button-banner-principal')?.texto.titulo}
            url={homePage?.textos?.find((txt) => txt.referencia === 'home-button-banner-principal')?.texto.conteudo}
            text={homePage?.textos?.find((txt) => txt.referencia === 'home-button-banner-principal')?.texto.titulo}
            target='_blank'
          />
        ) : null}
        <Link
          href='#quemsomos'
          passHref>
          <Image
            width='30px'
            height='30px'
            src='/img/arrowdown.svg'
            loader={customLoader}
            alt='Ir para o texto quem somos'
            priority
            style={{ cursor: 'pointer' }}
          />
        </Link>
      </Banner>
      <SobreHype
        dados={{
          banner: homePage?.imagens.find((img) => img.referencia === 'home-quem-somos-imagem'),
          texto1: homePage?.textos.find((txt) => txt.referencia === 'home-quem-somos-1'),
          texto2: homePage?.textos.find((txt) => txt.referencia === 'home-quem-somos-2'),
          texto3: homePage?.textos.find((txt) => txt.referencia === 'home-quem-somos-3'),
        }}
      />
      {depoimentos && <Depoimentos depoimentos={depoimentos} />}
      {lancamentos && (
        <NossosLancamentos
          texto={homePage?.textos.find((txt) => txt.referencia === 'home-lancamentos')}
          lancamentos={lancamentos}
        />
      )}

      <Politica
        dados={{
          banner: homePage?.imagens.find((img) => img.referencia === 'home-qualidade-imagem'),
          icones: homePage?.imagens?.filter((img) => img.referencia.includes('home-qualidade-politica')),
          textos: homePage?.textos?.filter((txt) => txt.referencia.includes('home-qualidade')),
        }}
      />
      <ValoresHype
        dados={{
          banner: homePage?.imagens.find((img) => img.referencia === 'home-valores-banner'),
          texto: homePage?.textos?.find((txt) => txt.referencia === 'home-valores-texto'),
          icones: homePage?.imagens?.filter((img) => img.referencia.includes('home-valores-icone')),
          textoIcones: homePage?.textos?.filter((txt) => txt.referencia.includes('home-valores-icone')),
          background: homePage?.imagens?.find((img) => img.referencia === 'home-valores-background'),
        }}
      />
      <Noticias
        artigos={artigos}
        background={homePage?.imagens.find((img: any) => img.referencia === 'home-noticias-background')}
      />
      <Formfale />
    </>
  );
}

export async function getStaticProps() {
  const homePageRes = await getCollectionCMSUnity('paginas/pagina/home');
  const homePage = homePageRes;

  const getEmpreendimentosCMS: any = await getCollectionCMSGraphQL(
    'Empreendimentos',
    payloadEmpreendimentos({
      limit: 5,
    }),
  );

  const lancamentos: any = getEmpreendimentosCMS.map((emp: any) => ({
    image: {
      url: emp?.imagens?.find((img: any) => img.referencia === 'thumbnail')?.imagem?.url || '',
      height: 600,
      width: 600,
    },
    id: emp.id.toString(),
    description: emp.textos.find((txt: any) => txt.referencia === 'conceito').texto.conteudo ?? '',
    link: `/apartamentos-a-venda/${emp.slug}`,
    title: emp.empreendimento,
    createdAt: emp.createdAt.toString(),
    slug: emp.slug,
  }));

  lancamentos.sort((la: any, lb: any) => {
    const lancamentoADate = new Date(la.createdAt);
    const lancamentoBDate = new Date(lb.createdAt);

    return lancamentoBDate.getTime() - lancamentoADate.getTime();
  });

  const cmsArtigos: any = await getCollectionCMSGraphQL(
    'Artigos',
    payloadArtigos({
      limit: 10,
    }),
  );

  const artigos = cmsArtigos.map((art: any) => ({
    imagem: {
      alt: art.conteudo.imagemDestacada.alt ?? '',
      width: art.conteudo.imagemDestacada.width.toString(),
      height: art.conteudo.imagemDestacada.height.toString(),
      url: art.conteudo.imagemDestacada.url,
    },
    slug: art.slug,
    title: art.conteudo.titulo,
    text: art.conteudo.conteudo,
    createdAt: art.createdAt.toString(),
  }));

  artigos.sort((blogA: any, blogB: any) => {
    const blogADate = new Date(blogA.createdAt);
    const blogBDate = new Date(blogB.createdAt);

    return blogBDate.getTime() - blogADate.getTime();
  });

  const depoimentos: any = await getCollectionCMSGraphQL(
    'Depoimentos',
    payloadDepoimentos({
      limit: 100,
    }),
  );

  lancamentos.length = 5;
  artigos.length = 5;

  const imagens: any = await getCollectionCMSGraphQL(
    'Imagens',
    payloadImagens({
      limit: 6,
      titulo: ['logo', 'facebook_icon', 'instagram_icon', 'youtube_icon', 'linkedin_icon', 'logo_escura'],
    }),
  );
  const logo = imagens.find((img: any) => img.titulo === 'logo')?.url;

  const listas: any = await getCollectionCMSGraphQL(
    'Listas',
    payloadListas({
      limit: 1,
      tituloLista: 'Site - header',
    }),
  );
  const routes = listas?.find((lt: any) => lt.tituloLista === 'Site - header')?.itens;

  const getSedes: any = await getCollectionCMSGraphQL(
    'Sedes',
    payloadSedes({
      limit: 2,
      nomes: ['Sede 1', 'Americana'],
    }),
  );
  const address = getSedes?.filter((sede: any) => ['Sede 1', 'Americana'].includes(sede.nome));

  const footerIcons = imagens?.filter((img: any) => ['facebook_icon', 'instagram_icon', 'youtube_icon', 'linkedin_icon', 'logo_escura'].includes(img.titulo),
  );

  const socialMedia = getSedes
    ?.filter((sede) => ['Sede 1'].includes(sede.nome))
    .map(({
      instagram, youtube, facebook, linkedin,
    }) => ({
      instagram,
      youtube,
      facebook,
      linkedin,
    }))[0];

  const getContatos: any = await getCollectionCMSGraphQL(
    'Contatos',
    payloadContatos({
      limit: 1,
      tituloLista: 'Geral',
    }),
  );
  const contact = getContatos?.filter((list) => ['Geral'].includes(list.tituloLista))[0].contatos || { contatos: [] };

  const textos: any = await getCollectionCMSGraphQL(
    'Textos',
    payloadTextos({
      limit: 1,
      titulo: 'Copyright',
    }),
  );
  const copy = textos?.find((txt) => txt.titulo === 'Copyright').conteudo;

  const headerData = { logo, routes };
  const footerData = {
    routes,
    logo,
    footerIcons,
    address,
    contact,
    copy,
    socialMedia,
  };

  return {
    props: {
      homePage,
      lancamentos,
      artigos,
      depoimentos,
      footerData,
      headerData,
    },
    revalidate: Number(process.env.REVALIDATE_INTERVAL),
  };
}
